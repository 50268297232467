.table-pagenation {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  margin: 4px;
  background-color: #ffffff;
}

.table-pagenation .pagenation-item {
  margin: 4px 2px;
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  border-radius: 4px;
  color: #767676;
}

.table-pagenation .pagenation-item.selected {
  background: #1379d8;
  color: #ffffff;
}
