@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700;900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
* {
  margin: 0;
  padding: 0;
  font-family: 'Noto Sans KR', sans-serif;
  font-family: 'Roboto', sans-serif;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  list-style-type: none;
  -webkit-margin-before: 0px;
          margin-block-start: 0px;
  -webkit-margin-after: 0px;
          margin-block-end: 0px;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  -webkit-padding-start: 0px;
          padding-inline-start: 0px;
  text-decoration: none;
  border: 0;
  outline: 0;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: auto;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  width: 100vw;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #ffffff;
}

.root {
  background-color: #e4e4e4;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

#root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.primary-btn {
  cursor: pointer;
  width: 200px;
  height: 48px;
  background-color: #1379d8;
  border-radius: 4px;
  color: #ffffff;
  font-size: 20px;
  margin: 0px 12px;
}

.delete-btn {
  cursor: pointer;
  width: 200px;
  height: 48px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #dc0000;
  font-size: 20px;
  margin: 0px 12px;
  border: 1px solid #dc0000;
}

.content-root {
  position: relative;
  background-color: #e4e4e4;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.content-root .title-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.content-root .title-container .title-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0px 24px;
  cursor: pointer;
}

.content-root .title-container .title-item.selected .title-label {
  color: #1379d8;
}

.content-root .title-container .title-item.selected .subtitle-label {
  color: #1379d8;
}

.content-root .title-container .title-item .title-label {
  font-size: 28px;
  font-weight: bold;
  color: #464255;
}

.content-root .title-container .title-item .subtitle-label {
  font-size: 18px;
  font-weight: 500;
  color: #a3a3a3;
}

.content-root .button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  margin: 12px 0px;
}

.content-root .table-container {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.content-root .table-container .header-section {
  height: 60px;
  background-color: #1379d8;
  border-radius: 4px;
  margin: 24px 0px 12px;
  color: #ffffff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0px 16px;
}

.content-root .table-container .header-section .item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.content-root .table-container .search-section {
  background-color: #ffffff;
  border-radius: 4px;
  margin: 12px 0px;
  padding: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}

.content-root .table-container .body-section {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  background-color: #ffffff;
  border-radius: 4px;
  margin: 12px 0px 24px;
  padding: 16px;
  min-height: 500px;
}

.content-root .table-container .body-section .row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 12px 0px;
  cursor: pointer;
  min-height: 48px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.content-root .table-container .body-section .row .item {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.content-root .table-container .footer-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}
