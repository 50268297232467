* {
  margin: 0;
  padding: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-before: 0;
          padding-block-start: 0;
  -webkit-padding-after: 0;
          padding-block-end: 0;
  border: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.cunderline-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 90px;
}

.cunderline-root .cunderline-label {
  font-size: 14px;
  color: #191919;
  font-weight: 500;
  margin-bottom: 4px;
}

.cunderline-root .cunderline-label .cunderline-input-required {
  color: #1379d8;
  vertical-align: middle;
}

.cunderline-root .cunderline-input {
  width: 300px;
  position: relative;
}

.cunderline-root .cunderline-input .cunderline-disabled-input {
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding-left: 12px;
  border-bottom: 1px solid #e5e5ec;
  background-color: #f1f1f5;
  color: #767676;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cunderline-root .cunderline-input .cunderline-input-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  left: 12px;
}

.cunderline-root .cunderline-input .cunderline-input-input {
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding-right: 22px;
  border-bottom: 1px solid #e5e5ec;
}

.cunderline-root .cunderline-input .cunderline-delete-btn {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.cunderline-root .cunderline-input .cunderline-delete-btn img {
  width: 100%;
}

.cunderline-root .cunderline-error-message {
  font-size: 12px;
  color: #dc0000;
  margin-top: 8px;
}
