.gnb-root {
  width: 300px;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;

  .gnb-item-container {
    cursor: pointer;
    width: 240px;
    height: 60px;
    color: #111111;
    background-color: #ffffff;
    border-radius: 4px;
    font-size: 20px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    margin-top: 16px;

    &:hover {
      background-color: #1379d8;
      color: #ffffff;
    }

    &.selected {
      background-color: #1379d8;
      color: #ffffff;
    }

    .gnb-item-label {
      background-color: transparent;
    }
  }

  .logout-btn {
    cursor: pointer;
    width: 240px;
    height: 48px;
    font-size: 16px;
    margin-top: 32px;
    background-color: #1379d8;
    color: #ffffff;
  }
}
