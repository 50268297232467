.cselect-root {
  position: relative;
  z-index: 10;
}

.cselect-root * {
  background-color: #ffffff;
}

.cselect-root .cselect-input {
  width: 260px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #e5e5ec;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 16px;
}

.cselect-root .cselect-input.focus {
  border: 1px solid #1379d8;
}

.cselect-root .cselect-input.unfocus {
  border: 1px solid #e5e5ec;
}

.cselect-root .cselect-input .cselect-label.selected {
  color: #111111;
}

.cselect-root .cselect-input .cselect-label.unselected {
  color: #767676;
}

.cselect-root .cselect-input .cselect-under-arrow {
  width: 24px;
  height: 24px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  top: 12px;
  right: 16px;
}

.cselect-root .cselect-input .cselect-under-arrow img {
  width: 16px;
  height: 8px;
}

.cselect-root .cselect-option {
  -webkit-box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
          box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  -webkit-transition-property: max-height;
  transition-property: max-height;
  position: absolute;
}

.cselect-root .cselect-option.hide {
  max-height: 0px;
  overflow: hidden;
}

.cselect-root .cselect-option.show {
  max-height: 1000px;
}

.cselect-root .cselect-option .cselect-option-item {
  width: 260px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 16px;
  color: #111111;
}

.cselect-root .cselect-option .cselect-option-item:not(:last-child) {
  border-bottom: 1px solid #f1f1f5;
}

.cselect-root .cselect-option .cselect-option-item:hover {
  background: rgba(19, 121, 216, 0.05);
}
