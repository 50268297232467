.gnb-root {
  width: 300px;
  min-height: 100vh;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #ffffff;
}

.gnb-root .gnb-item-container {
  cursor: pointer;
  width: 240px;
  height: 60px;
  color: #111111;
  background-color: #ffffff;
  border-radius: 4px;
  font-size: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding-left: 16px;
  margin-top: 16px;
}

.gnb-root .gnb-item-container:hover {
  background-color: #1379d8;
  color: #ffffff;
}

.gnb-root .gnb-item-container.selected {
  background-color: #1379d8;
  color: #ffffff;
}

.gnb-root .gnb-item-container .gnb-item-label {
  background-color: transparent;
}

.gnb-root .logout-btn {
  cursor: pointer;
  width: 240px;
  height: 48px;
  font-size: 16px;
  margin-top: 32px;
  background-color: #1379d8;
  color: #ffffff;
}
