.table-pagenation {
  width: fit-content;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  margin: 4px;
  background-color: #ffffff;

  .pagenation-item {
    margin: 4px 2px;
    cursor: pointer;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    border-radius: 4px;
    color: #767676;

    &.selected {
      background: #1379d8;
      color: #ffffff;
    }
  }
}
