* {
  margin: 0;
  padding: 0;
  -webkit-margin-start: 0;
          margin-inline-start: 0;
  -webkit-margin-end: 0;
          margin-inline-end: 0;
  -webkit-margin-before: 0;
          margin-block-start: 0;
  -webkit-margin-after: 0;
          margin-block-end: 0;
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  -webkit-padding-end: 0;
          padding-inline-end: 0;
  -webkit-padding-before: 0;
          padding-block-start: 0;
  -webkit-padding-after: 0;
          padding-block-end: 0;
  border: 0;
  outline: 0;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.cunderline-adaptive-root {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 90px;
  margin-top: 4px;
}

.cunderline-adaptive-root .cunderline-adaptive-input {
  width: 300px;
  position: relative;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-disabled-input {
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding-left: 12px;
  border-bottom: 1px solid #e5e5ec;
  background-color: #f1f1f5;
  color: #767676;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-input-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  left: 12px;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-input-input {
  width: 100%;
  height: 48px;
  font-size: 15px;
  padding-right: 22px;
  border-bottom: 1px solid #e5e5ec;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-delete-btn {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 15px;
  right: 15px;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-delete-btn img {
  width: 100%;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-label {
  font-size: 14px;
  color: #1379d8;
  font-weight: 500;
  margin-bottom: 4px;
  -webkit-transition-duration: 150ms;
          transition-duration: 150ms;
  -webkit-transition-property: font-size, background-color, color, top;
  transition-property: font-size, background-color, color, top;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-label.active {
  position: absolute;
  background-color: #ffffff;
  color: #1379d8;
  font-size: 14px;
  top: -6px;
  left: 0px;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-label.deactive {
  position: absolute;
  color: #767676;
  background-color: transparent;
  font-size: 15px;
  top: 15px;
  left: 0px;
}

.cunderline-adaptive-root .cunderline-adaptive-input .cunderline-adaptive-label .cunderline-adaptive-input-required {
  color: #1379d8;
  vertical-align: middle;
}

.cunderline-adaptive-root .cunderline-adaptive-error-message {
  font-size: 12px;
  color: #dc0000;
  margin-top: 8px;
}
