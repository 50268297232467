.coupon-root {
  display: flex;
  justify-content: center;
  align-items: center;

  .qr-container {
    width: 300px;
    height: 340px;
    background-color: #ffffff;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .name-label {
      font-size: 22px;
      font-weight: 600;
      margin-top: 20px;
    }
  }
}
